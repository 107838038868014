// import NavigationBar from "./components/NavigationBar/NavigationBar";
import ReactGA from 'react-ga';
import Home from './pages/Home';
import Ourwork from './pages/Ourwork';
import React from "react";
// eslint-disable-next-line
import { Spinner } from "react-bootstrap"
import {
  Switch,
  Route
} from "react-router-dom";
function App() {

  // GA Init
  const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
  // End GA
 
  return (
    <div>
    <div className="App">
    <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/ourwork" component={Ourwork} />
      </Switch>
    </div>
    </div>
  );
}

export default App;
