import React from 'react';
import SkeletonElement from './SkeletonElement';
import './Skeleton.css';
import Shimmer from './Shimmer';
import { Container, Col, Row} from 'react-bootstrap'

const SkeletonContact = () => {
    return (
        <div className="skeleton-Services">
            <Container className='mt-5 services px-5'>
            <Row>
            <Col md={4}>
                </Col>
                <Col md={4}>
                    <h1 className='text-center mt-5' data-aos="zoom-in-up" ><SkeletonElement element="title-about" />
                    <Shimmer /></h1>
                </Col>
                <Col md={4}>
                </Col>
            </Row>
            <div className='form mt-5'>
                
                    <Row>
                        <Col md={6} className="form-group" data-aos="fade-up-right"> 
                        <h1 className='text-center' data-aos="zoom-in-up" ><SkeletonElement element="form-contact" />
                    <Shimmer /></h1>
                        </Col>
                        <Col md={6} className="form-group" data-aos="fade-up-left"> 
                        <h1 className='text-center' data-aos="zoom-in-up" ><SkeletonElement element="form-contact" />
                    <Shimmer /></h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} className="form-group " data-aos="fade-up"> 
                        <h1 className='text-center' data-aos="zoom-in-up" ><SkeletonElement element="form-contact" />
                    <Shimmer /></h1>
                        </Col>
                    </Row>
                    <Row>
                    <Col md={12} className="form-group " data-aos="fade-up">
                    <h1 className='text-center' data-aos="zoom-in-up" ><SkeletonElement element="form-contact" />
                    <Shimmer /></h1>
                    </Col>
                    <Row>
                    <Col md={4}>
                </Col>
                <Col md={4}>
                    <h1 className='text-center mt-5' data-aos="zoom-in-up" ><SkeletonElement element="title-about" />
                    <Shimmer /></h1>
                </Col>
                <Col md={4}>
                </Col>
                    </Row>
                    </Row>
            </div>
        </Container>          
        </div>
    )
}

export default SkeletonContact;