import React from 'react';
import SkeletonElement from './SkeletonElement';
import './Skeleton.css';
import Shimmer from './Shimmer';
import { Container, Col, Row} from 'react-bootstrap'

export default function SkeletonCLients() {
  return (
    <div className="skeleton-Services">
    <Container className='mt-5 services px-5'>
    <Row>
        <Col md={4}>
        </Col>
        <Col md={4}>
            <h1 className='text-center' data-aos="zoom-in-up" ><SkeletonElement element="title-about" />
            <Shimmer /></h1>
        </Col>
        <Col md={4}>
        </Col>
    </Row>
    <Row>
        <Col md={12}>
            <p className='text-center mt-3' data-aos="zoom-in-down"><SkeletonElement element="image-clients" /><Shimmer /></p>
        </Col>
    </Row> 
    
</Container>          
</div>
  )
}
