import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import './navbar.css'
import { Image } from 'react-bootstrap'
import { Link } from 'react-scroll'
import { Link as Links} from 'react-router-dom'
import { NavHashLink} from 'react-router-hash-link'


const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
}

  return (
    <div className="navbar fixed-top">
        <div className="navbar-links_logo"> 
            <Links to='/#top'>
              <Image src="/assets/img/logo_prototype-05.png" alt="Logo" className='img-fluid'/>
            </Links>
        </div>
      <div className="navbar-links">
        <ul>
          <li className='nav-item'><Link to="hero" spy={true} smooth={true} offset={-100} duration={300}>HOME</Link></li>
          <li className='nav-item'><Link to="about" spy={true} smooth={true} offset={-150} duration={300}>ABOUT US</Link></li>
          <li className='nav-item'><Link to="services" scroll={el => scrollWithOffset(el)} spy={true} smooth={true} offset={-100} duration={300}>WHAT WE DO</Link></li>
          <li className='nav-item'><Link to="whyus" spy={true} smooth={true} offset={-100} duration={300}>MANIFESTO</Link></li>
          <li className='nav-item'><NavHashLink to="/ourwork#top" spy={true} smooth={true} offset={-100} duration={300}>OUR WORK</NavHashLink></li>
          <li ><Link className="btn"to="contact" spy={true} smooth={true} offset={-50} duration={300}>START A PROJECT</Link></li>
        </ul>
      </div>
      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
        <div className="navbar-menu_container scale-up-center">
          <div className="navbar-menu_container-links">
          <p><Link to="hero" spy={true} smooth={true} offset={-100} duration={300}>HOME</Link></p>
          <p><Link to="about" spy={true} smooth={true} offset={-150} duration={300}>ABOUT US</Link></p>
          <p><Link to="services" spy={true} smooth={true} offset={-100} duration={300}>WHAT WE DO</Link></p>
          <p><Link to="whyus" spy={true} smooth={true} offset={-100} duration={300}>MANIFESTO</Link></p>
          <p><NavHashLink to="/ourwork#top" spy={true} smooth={true} offset={-100} duration={300}>OUR WORK</NavHashLink></p>
          <p><Link to="contact" spy={true} smooth={true} offset={-50} duration={300}>START A PROJECT</Link></p>
          </div>
        </div>
        )}
      </div>
      
    </div>
  );
};

export default Navbar;