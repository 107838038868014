import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import './portfolio.css'

export default function Portfolio() {
    const image =["1","2","3","4","5","6","7","8","9","10"]
  return (
    <div>

    <Container className='clients mt-5 px-5'>

<Row>
    <Col lg={12} data-aos="fade-up">
    <h1 className='text-center mt-3' >PHOTOSHOOT</h1>
    </Col>
</Row>
{
    image.map((img, index) => {
        return(
            <React.Fragment key={index}>
                <Row className='mt-5'>
                    <Col lg={12} className='text-center d-flex justify-content-center'>
                        
                        <div className='client'>

                        <Image src={`assets/img/ourwork/photo${img}.png`} alt="photoshoot" data-aos="fade-left"/>
                        </div>
                   
                        </Col>
                </Row>
            </React.Fragment>
        )
    })
}

<Row>
    <Col lg={12} data-aos="fade-up">
    <h1 className='text-center mt-5' >VIDEO PRODUCTION</h1>
    </Col>
</Row>
{
    image.map((img, index) => {
        return(
            <React.Fragment key={index}>
                <Row className='mt-5'>
                    <Col lg={12} className='text-center d-flex justify-content-center'>
                        
                        <div className='client'>

                        <Image src={`assets/img/ourwork/video${img}.png`} alt="videoproductions" data-aos="fade-left"/>
                        </div>
                   
                        </Col>
                </Row>
            </React.Fragment>
        )
    })
}
        </Container>
</div>
    
  )
}
