import React from 'react'
import { Col, Row, Container, Image } from 'react-bootstrap'
import './discover.css'

const Discover = (props) => {
  const datas = props.data.items
  return (
    <div>
           <Container className="mt-5 process px-5 d-flex">
            <Row className="d-flex row">
                <Col lg={5} className="image">
                   <Image src="/assets/img/about.jpg" className="img" data-aos="fade-up-right"  data-aos-offset="-100" />
                </Col>
                <Col lg={7} className='mt-3 ml-3'>
                  <h1 data-aos="flip-right">{props.data.title}</h1>
                    <Row>
                      {
                        datas.map(data => {
                          return(
                           <React.Fragment key={data.title}>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                           <i className={`${data.icon} bx-md icon`}></i>
                        <h4>{data.title}</h4>
                        <p>{data.description}</p>
                      </Col>
                           </React.Fragment> 
                          )
                        })
                      }
                    </Row>
                </Col>
            </Row>
        </Container></div>
  )
}

export default Discover