import React from 'react'
import Navbar from '../components/Navbar/Navbar1'
import Portfolio from '../components/Portfolio/Portfolio'
import Footer from '../components/Footer/Footer'
import '../style/style.css'

export default function Ourwork() {
  return (
    <div className="App">
        <Navbar />
        <Portfolio />
        <Footer /> 

     </div>
  )
}
