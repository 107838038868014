import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import './services.css'

const Services = (props) => {
    const services = props.data
    const items = services.items
  return (
    <div>
        <Container className='mt-5 services px-5' id='services'>
            <Row>
                <Col md={12}>
                    <h1 className='text-center' data-aos="zoom-in-up" >{services.title}</h1>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    <p className='text-center mt-3' data-aos="zoom-in-down">{services.description}</p>
                </Col>
            </Row>
            <Row className='mt-5'>
                {
                items.map(item=> {
                    return (
                        <React.Fragment key={item.title}>
                            <Col md={4} className='d-flex justify-content-center align-items-center icon mt-3'>
                                <div className='box text-center' data-aos="flip-right" data-aos-duration="3000" data-aos-offset="150" ><Image src={`assets/img/portfolio/${item.image}`}></Image></div>
                                <h3 className='text-center' data-aos="zoom-out">{item.title}</h3>
                                <p className='text-center mt-3' data-aos="zoom-out">{item.description}</p>
                            </Col>
                        </React.Fragment>
                    )
                })
            }
            </Row>
            
        </Container>
    </div>
  )
}

export default Services