import React from 'react';
import SkeletonElement from './SkeletonElement';
import './Skeleton.css';
import Shimmer from './Shimmer';
import { Col, Row, Container } from 'react-bootstrap'

const SkeletonProcess = () => {
    return (
        <div className="skeleton-Process">
            <Container className="mt-5 px-5">
            <Row className="d-flex row">
                <Col lg={5} className="image">
                <SkeletonElement element="image" /><Shimmer />
                </Col>
                <Col lg={7} className='mt-3 ml-3'>
                  <h1 data-aos="flip-right"><SkeletonElement element="title" /><Shimmer /></h1>
                    <Row>
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                           <React.Fragment>
                           <Col lg={6} className="item" data-aos="fade-up-right">
                        <h4><SkeletonElement element="title" /><Shimmer /></h4>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                        <p><SkeletonElement element="text" /></p>
                      </Col>
                           </React.Fragment> 
                    </Row>
                </Col>
            </Row>
        </Container>
            
        </div>
    )
}

export default SkeletonProcess;