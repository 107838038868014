import React from 'react';
import SkeletonElement from './SkeletonElement';
import './Skeleton.css';
import Shimmer from './Shimmer';
import { Col, Row, Container } from 'react-bootstrap'

const SkeletonAbout = () => {
    return (
        <div className="skeleton-about">
             <Container className="about px-5" > 
            <Row>
            <Col md={4}>
                </Col>
                <Col md={4}>
                    <h1 className='text-center mt-5' data-aos="zoom-in-up" ><SkeletonElement element="title-about" />
                    <Shimmer /></h1>
                </Col>
                <Col md={4}>
                </Col>
                <Col md="{12}" className='mt-3'>
                    <p className='text-center'data-aos="fade-down" ><SkeletonElement element="text" /><Shimmer /></p>
                    <p className='text-center'data-aos="fade-down" ><SkeletonElement element="text" /><Shimmer /></p>
                    <p className='text-center'data-aos="fade-down" ><SkeletonElement element="text" /><Shimmer /></p>
                </Col>
            </Row>
        </Container>
            
        </div>
    )
}

export default SkeletonAbout;