import React from 'react'
import { Col, Row, Container, Card } from 'react-bootstrap'
import './whyus.css'

const Whyus = (props) => {
  const whyus = props.data
  const items = whyus.items
  return (
    <div >
        <Container className='mt-5 whyus px-5' id='whyus'>
            <Row><Col>
            <h1 className='text-center' data-aos="zoom-in" >{whyus.title}</h1>
            </Col></Row>
            <Row><Col md="{12}" className='mt-3'>
                    <p className='text-center' data-aos="zoom-out">{whyus.description}</p>
                </Col></Row>
            <Row className='mt-3'>
              {

                items.map(item => {
                  return(
                    <React.Fragment key={item.title}>
                   <Col md={4} data-aos="flip-left">
                  <Card style={{ width: '100%' }} className="box">
                    <Card.Body>
                    <Card.Title>{item.title}</Card.Title>
                    <Card.Text>
                      {item.description}
                    </Card.Text>
                  </Card.Body>
                </Card>
                </Col>
                    </React.Fragment>
                )
              })
            }
                
            </Row>
        </Container>
    </div>
  )
}

export default Whyus