import React from 'react';
import SkeletonElement from './SkeletonElement';
import './Skeleton.css';
import Shimmer from './Shimmer';

const SkeletonHero = () => {
    return (
        <div className="skeleton-hero">
            <Shimmer />
            <SkeletonElement element="title" />
            <SkeletonElement element="title" />
            <hr></hr>
            <SkeletonElement element="title" />
        </div>
    )
}

export default SkeletonHero;