import React from 'react'
import { Col, Row, Container } from 'react-bootstrap'
import './about.css'

const About = (props) => {
  return (
    <div className='about-main' id='about' >
            <Container className="about px-5" > 
            <Row>
                <Col md="{12}">
                   <h1 className='text-center mt-5 ' data-aos="flip-left">{props.data.title}</h1>
                </Col>
                <Col md="{12}" className='mt-3'>
                    <p className='text-center'data-aos="fade-down" >{props.data.description}</p>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default About