import React from 'react'
import { Container, Col, Row, Image } from 'react-bootstrap'
import './clients.css'

export default function Clients() {
  return (
    <div>
        <Container className='clients mt-5 px-5'>

    <Row>
        <Col lg={12} data-aos="fade-up">
        <h1 className='text-center mt-3' >SELECTED CLIENTS</h1>
        </Col>
    </Row>
    <Row className='mt-5'>
        <Col lg={12} className='text-center d-flex justify-content-center'>
            <a href="https://monogram.co.id">
            <div className='client'>

            <Image src="assets/img/clients/clients.png" alt="mobil1" data-aos="fade-left"/>
            </div>
    
            
        </a>
            </Col>
    </Row>
    
            </Container>
</div>
  )
}
