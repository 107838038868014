import Hero from "../components/hero/Hero";
import About from "../components/About/About";
import Discover from "../components/Discover/Discover";
import Services from "../components/services/Services";
import Navbar from "../components/Navbar/Navbar"
import '../style/style.css'
import Whyus from "../components/whyus/Whyus";
import Contact from "../components/contact/Contact";
import Clients from '../components/Clients/Clients';
import Footer from "../components/Footer/Footer";
import React, { useEffect, useState} from "react";

// eslint-disable-next-line
import { Spinner } from "react-bootstrap"
import AOS from "aos";
import SkeletonHero from '../skeletons/SkeletonHero';
import SkeletonAbout from '../skeletons/SkeletonAbout';
import SkeletonProcess from '../skeletons/SkeletonProcess';
import SkeletonServices from '../skeletons/SkeletonServices';
import SkeletonWhyus from '../skeletons/SkeletonWhyus';
import SkeletonCLients from "../skeletons/SkeletonCLients";
import SkeletonContact from '../skeletons/SkeletonContact';
import "aos/dist/aos.css";
const axios = require('axios').default;

const Home = ()  => {
    const [data, setData] = useState()

  const fetchApi = async () => {
    try {
      // eslint-disable-next-line
      const getData = await axios.get(`${process.env.REACT_APP_API_URL}`)
      .then(function (response) {
        // handle success
        console.log(response.data)
        setData(response.data);
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchApi();
  },[])
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  if(data === undefined){
    return (
      <div className="App">
        <Navbar />
      <div className="d-flex justify-content-center align-items-center center">
    <SkeletonHero />
      </div>
    <SkeletonAbout />
    <SkeletonProcess />
    <SkeletonServices />
    <SkeletonCLients />
    <SkeletonWhyus />
    <SkeletonContact />
    <Footer />
      </div>
    );
  }
  console.log(data);
  return (
    <div className="App">
      <Navbar />
      <div className="hero" id='hero'>
      <Hero data={data[4].hero}/>
      </div>
      <div data-aos="fade-up" id='about'>
      <About data={data[3].about} />
      </div>
        
      <Discover data={data[0].process}/>
      <div id="service">

      <Services data={data[1].service} />
      </div>
      <Clients />
      <Whyus data={data[2].whyus}/>
      <Contact />
      <div data-aos="fade-up">

      <Footer />
      </div>
    </div>
  )
}
export default Home
